import React from "react";

function HomeHero() {
  return (
    <section className="bg-slate-900 pb-20 bg-[url('/dot-grid.png')]">
      <div className="container py-24 flex flex-row items-center">
        <div className="w-1/2">
          <h1 className="mb-0 pb-4 text-secondary text-5xl">
            Explore Popular Languages
          </h1>
          <div className="prose">
            <p className="prose-xl text-slate-400 pr-14">
              Don't be intimidated by the world of code. Codepick.io helps you
              compare different languages so you can pick the right one, whether
              it's your first language or your fifth.
            </p>
          </div>
        </div>
        <div className="w-1/2 flex justify-end">
          <img src="side-code.png" className="w-10/12" />
        </div>
      </div>
    </section>
  );
}

export default HomeHero;
