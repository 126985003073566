import React from "react";
const _ = require("lodash");
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import HomeHero from "../pageSections/home/HomeHero";
import renderLanguageLogo from "../components/Language/RenderLanguageLogo";
import IconRight from "../components/Icons/IconRight";

export default function HomePage({ data }) {
  const languages = data.allLanguagesJson.edges;

  const popularUses = [
    {
      name: "Video Games",
      slug: "video-games"
    },
    {
      name: "Websites",
      slug: "websites"
    },
    {
      name: "Mobile Apps",
      slug: "mobile-apps"
    },
    {
      name: "Hardware",
      slug: "hardware"
    },
    {
      name: "Cloud",
      slug: "cloud"
    },
    {
      name: "AI",
      slug: "artificial-intelligence"
    },
    {
      name: "Blockchain",
      slug: "blockchain"
    }
  ]
  
  const popularTags = [
    {
      name: "Easy Syntax",
      slug: "syntax/easy"
    },
    {
      name: "High Demand",
      slug: "demand/high"
    },
    {
      name: "Easy to Get Help",
      slug: "getting-help/easy"
    }, 
  ]

  const renderLanguages = () => {
    return (
      <div className="grid gap-4 grid-cols-2 md:grid-cols-4 grid-auto-rows items-center">
        {languages.map((lang) => (
          <Link
            to={`language/${lang.node.slug}`}
            className="flex text-xl text-slate-600 font-bold h-full"
          >
            <div className="bg-white hover:bg-secondary hover:text-white drop-shadow-sm text-center flex flex-col p-4">
              <div className="flex flex-col md:flex-row w-full items-center">
              <div className="w-1/2 md:w-1/6 bg-white border p-2">
                  {renderLanguageLogo(lang.node.name)}
                </div>
                <div className="text-center md:text-left w-4/6 md:w-5/6 pt-2 md:pt-0 md:pl-4">
                    {lang.node.name}
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    );
  };

  const renderCommonUses = () => {
    return (
      <div className="flex flex-wrap flex-row items-center text-lg font-bold">
          <h2 className="block w-full text-left md:inline-block md:pr-2 text-4xl pb-8 text-gray-100">I want to build</h2>
          <div className="grid gap-2 grid-cols-2 md:grid-cols-7 grid-auto-rows">
            {popularUses.map((lang) => (
              <a
                href={`/used-for/${lang.slug}`}
                className="capitalize text-center font-bold px-6 py-2 mb-2 border-2 border-black hover:bg-black text-black hover:text-white"
              >
                {lang.name}
              </a>
            ))}
          
          </div>
      </div>
    );
  };

  const renderPopularTags = () => {
    return (
      <div className="flex flex-wrap flex-row justify-center">
        {popularTags.map((tag) => (
          <Link to={`/${tag.slug}`} className="capitalize font-bold m-2 text-center inline-flex justify-center items-center flex-col px-6 py-2 mb-2 border-2 border-slate-800 hover:bg-slate-800 text-slate-900 hover:text-white">
            {tag.name}
          </Link>
        ))}
      </div>
    );
  };

  return (
    <Layout>
      <Helmet title={`${config.siteTitle}`} />
      <HomeHero />
      <div className="common-uses bg-secondary">
        <div className="container flex flex-col justify-start text-left items-center py-14">
          <div className="w-full">
            {renderCommonUses()}
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center items-center py-24 bg-gray-100">
        <div className="container">
          <h2 className="text-2xl text-center pb-6 text-slate-800 font-heavy font-bold">
            Browse by Popular Languages
          </h2>
          {renderLanguages()}
          <div className="flex w-full pt-6 justify-center">
            <a href="/languages" className="capitalize font-bold m-2 text-center inline-flex justify-center items-center flex-col px-6 py-2 mb-2 border-2 border-slate-800 hover:bg-slate-800 text-slate-900 hover:text-white">
              Browse All Languages
            </a>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center items-center py-12">
        <h2 className="text-2xl pb-6">Other Popular Tags</h2>
        {renderPopularTags()}
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query Languages {
    allLanguagesJson {
      distinct(field: used_for)
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`;
